import "./App.css";
import XLSX from "xlsx";

import { useState } from "react";

function App() {
  const [errors, setErrors] = useState(false);
  const doSubmit = async (event) => {
    event.preventDefault();
    setErrors(false);

    var file = document.getElementById("fileForUpload").files[0];
    if (!file) {
      // set error message saying user has to choose the XLSX file
      setErrors("You have to select a valid XLSX file");
      return;
    }

    var reader = new FileReader();
    var outputData = [];

    reader.onload = async (event) => {
      var fileData = event.target.result;
      var workbook = XLSX.read(fileData, {
        type: "binary",
      });

      workbook.SheetNames.forEach(function (sheetName) {
        var csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);

        csv = csv.split(/\r?\n/g);
        csv.forEach(function (value, index) {
          csv[index] = value.split(",");
        });
        outputData.push(csv);
      });

      const response = await fetch("api/createXML", {
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(outputData),
      });

      // debugger;

      if (response.ok) {
        const jsonResponse = await response.json();

        // console.log(jsonResponse);
        // check jsonResponse.validationErrors
        // if empty, show green message
        // if not empty, show red message // something went wrong with validation
        if (jsonResponse.errorMessage) {
          // show all good message
          return setErrors(`Error
          ${JSON.stringify(jsonResponse.errorMessage)}
          `);
        } else if (jsonResponse.validationErrors) {
          // set error message
          return setErrors(`Error validating against Altinn schema
            ${JSON.stringify(jsonResponse.validationErrors)}
            `);
        }

        const blob = new Blob([jsonResponse.fileContent], {
          type: "application/xml",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        // make this a name for the company or something
        a.download = jsonResponse.filename || "oppgave.xml";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        // set error in state, to show generic error message
        setErrors(
          `Error communicating with server.
          This usually means something was unexpected in the input from
          the xlsx document or a use-case the server can't handle. Technical description: ${response.status} - ${response.statusText}`
        );
      }
    };

    reader.onerror = function (error) {
      // do file validation here
      // console.log(error);
      setErrors("Error reading XLSX file");
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div
      style={{
        // background: "blue",
        width: 500,
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img src="logo.png" alt="" style={{ width: 200, margin: "50px auto" }} />
      <form
        action=""
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={doSubmit}
      >
        <input id="fileForUpload" type="file" name="fileToUpload" />
        <input
          type="submit"
          value="Last opp Excel-fil"
          name="submit"
          style={{ marginTop: 40 }}
        />
      </form>

      {errors ? (
        <div
          style={{
            backgroundColor: "#fdf2f2",
            borderRadius: 15,
            marginTop: 40,
            padding: 20,
            color: "#9b1c1c",
          }}
        >
          {errors}
        </div>
      ) : null}

      {/*
      <div style={{ marginTop: 150 }}>
        <button>Show debug log</button>
      </div>
      */}
    </div>
  );
}

export default App;
